* {
    box-sizing: border-box;
}
@font-face {
    font-family: 'Tomato Grotesk';
    src: url('./assets/fonts/tomatogrotesk-regular-webfont.woff2') format('woff2'),
        url('./assets/fonts/tomatogrotesk-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tomato Grotesk bold';
    src: url('./assets/fonts/tomatogrotesk-bold-webfont.woff2') format('woff2'),
        url('./assets/fonts/tomatogrotesk-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
html {
    margin: 0;
    padding: 0;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
body {
    margin: 0;
    height: 100vh;
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.87);
    background-color: #f5f2ef;
    padding: 75px 0 0 0;
}

@media only screen and (max-width: 900px) {
    body {
        padding-top: 60px;
    }
}
code {
    font-family: 'Open Sans', sans-serif;
}
